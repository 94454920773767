<template>
    <div class="sidebar-page-container">
        <div class="auto-container">
            <div class="row clearfix">
                <!--Content Side-->
                <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <div class="property-grid-view">
                        <div class="upper-box clearfix">
                            <div class="sec-title">
                                <span class="title"></span>
                                <h2>إبحث عن العقار المناسب لك</h2>
                            </div>

                            <!-- <ul class="filter-options">
                                    <li>
                                    <a href="property-list-view.html"
                                        ><span class="la la-th-list"></span
                                    ></a>
                                    </li>
                                    <li class="active">
                                    <a href="property-grid-view.html"
                                        ><span class="la la-th-large"></span
                                    ></a>
                                    </li>
                                </ul> -->
                        </div>

                        <div class="row">
                            <!-- Property Block -->
                            <div
                                v-if="properties.length"
                                v-for="property in properties"
                                :key="property.id"
                                :class="
                                    'property-block all mix col-xl-4 col-lg-6 col-md-6 col-sm-12 category-' +
                                    property.category_id
                                "
                            >
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure
                                            @click="
                                                changeLocation(
                                                    `/property/${property.id}`
                                                )
                                            "
                                            class="image pointer"
                                        >
                                            <img
                                                :src="property.image_url"
                                                alt=""
                                            />
                                        </figure>
                                        <!-- <span class="for">FOR SALE</span>
                                                <span class="featured">FEATURED</span> -->
                                        <ul class="info clearfix">
                                            <li class="text-left">
                                                <div class="rating text-info">
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="option-box">
                                            <li>
                                                <a
                                                    :href="property.image_url"
                                                    class="lightbox-image"
                                                    data-fancybox="property"
                                                    ><i class="la la-camera"></i
                                                ></a>
                                            </li>
                                            <li>
                                                <a
                                                    @click.prevent="
                                                        toggleFavourite(
                                                            property.id
                                                        )
                                                    "
                                                    ><i
                                                        :class="
                                                            'la la-heart' +
                                                            (favourites.includes(
                                                                property.id
                                                            )
                                                                ? ' text-danger'
                                                                : '')
                                                        "
                                                    ></i
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <ul class="tags">
                                            <li>
                                                <router-link
                                                    :to="
                                                        '/properties?category=' +
                                                        property.category.id
                                                    "
                                                    >{{
                                                        property.category.title
                                                    }}</router-link
                                                >
                                            </li>
                                        </ul>
                                        <!-- <div class="thumb">
                                            <img src="images/resource/thumb-5.jpg" alt="" />
                                        </div> -->
                                        <h3>
                                            <router-link
                                                :to="'/property/' + property.id"
                                                >{{
                                                    property.title
                                                }}</router-link
                                            >
                                        </h3>
                                        <div class="location">
                                            <i class="la la-map-marker"></i>
                                            {{ property.address }}
                                        </div>
                                        <ul class="property-info clearfix">
                                            <li>
                                                <i class="flaticon-users"></i>
                                                {{ property.guests }} أشخاص
                                            </li>
                                            <li>
                                                <i class="flaticon-bed"></i
                                                >{{ property.beds }} أسرة
                                            </li>
                                            <!-- <li>
                                                <i class="la la-wifi"></i>
                                                {{
                                                    property.wifi
                                                        ? "متوفر"
                                                        : "غير متوفر"
                                                }}
                                            </li>
                                            <li>
                                                <i class="flaticon-bathtub"></i>
                                                {{ property.bathrooms }} حمامات
                                            </li> -->
                                        </ul>
                                        <div class="property-price clearfix">
                                            <div class="read-more">
                                                <router-link
                                                    :to="
                                                        '/property/' +
                                                        property.id
                                                    "
                                                    class="theme-btn"
                                                    >التفاصيل</router-link
                                                >
                                            </div>
                                            <div class="price">
                                                {{ property.price_1 }} ريال
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-12 text-center">
                                لا يوجد عقارات
                            </div>
                            <div class="col-12 text-center mt-2">
                                <button
                                    v-if="more"
                                    @click="getProperties(currentPage + 1)"
                                    class="theme-btn btn-style-one text-white"
                                >
                                    <i
                                        class="fa fa-plus"
                                        aria-hidden="true"
                                    ></i>
                                    المزيد
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="otherProperties.length"
                        class="property-grid-view"
                    >
                        <div class="upper-box clearfix">
                            <div class="sec-title">
                                <span class="title"></span>
                                <h2>أماكن أخرى قد تعجبك</h2>
                            </div>
                        </div>

                        <div class="row">
                            <!-- Property Block -->
                            <div
                                v-for="property in otherProperties"
                                :key="property.id"
                                :class="
                                    'property-block all mix col-xl-4 col-lg-6 col-md-6 col-sm-12 category-' +
                                    property.category_id
                                "
                            >
                                <div class="inner-box">
                                    <div class="image-box">
                                        <figure
                                            @click="
                                                changeLocation(
                                                    `/property/${property.id}`
                                                )
                                            "
                                            class="image pointer"
                                        >
                                            <img
                                                :src="property.image_url"
                                                alt=""
                                            />
                                        </figure>
                                        <!-- <span class="for">FOR SALE</span>
                                                <span class="featured">FEATURED</span> -->
                                        <ul class="info clearfix">
                                            <li class="text-left">
                                                <div class="rating text-info">
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            1
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            2
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            3
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            4
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                    <span
                                                        :class="
                                                            property.average_overall_rating >=
                                                            5
                                                                ? 'la la-star'
                                                                : 'la la-star-o'
                                                        "
                                                    ></span>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="option-box">
                                            <li>
                                                <a
                                                    :href="property.image_url"
                                                    class="lightbox-image"
                                                    data-fancybox="property"
                                                    ><i class="la la-camera"></i
                                                ></a>
                                            </li>
                                            <li>
                                                <a
                                                    @click.prevent="
                                                        toggleFavourite(
                                                            property.id
                                                        )
                                                    "
                                                    ><i
                                                        :class="
                                                            'la la-heart' +
                                                            (favourites.includes(
                                                                property.id
                                                            )
                                                                ? ' text-danger'
                                                                : '')
                                                        "
                                                    ></i
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="lower-content">
                                        <ul class="tags">
                                            <li>
                                                <router-link
                                                    :to="
                                                        '/properties?category=' +
                                                        property.category.id
                                                    "
                                                    >{{
                                                        property.category.title
                                                    }}</router-link
                                                >
                                            </li>
                                        </ul>
                                        <!-- <div class="thumb">
                                            <img src="images/resource/thumb-5.jpg" alt="" />
                                        </div> -->
                                        <h3>
                                            <router-link
                                                :to="'/property/' + property.id"
                                                >{{
                                                    property.title
                                                }}</router-link
                                            >
                                        </h3>
                                        <div class="location">
                                            <i class="la la-map-marker"></i>
                                            {{ property.address }}
                                        </div>
                                        <ul class="property-info clearfix">
                                            <li>
                                                <i class="flaticon-users"></i>
                                                {{ property.guests }} أشخاص
                                            </li>
                                            <li>
                                                <i class="flaticon-bed"></i
                                                >{{ property.beds }} أسرة
                                            </li>
                                            <!-- <li>
                                                <i class="la la-wifi"></i>
                                                {{
                                                    property.wifi
                                                        ? "متوفر"
                                                        : "غير متوفر"
                                                }}
                                            </li>
                                            <li>
                                                <i class="flaticon-bathtub"></i>
                                                {{ property.bathrooms }} حمامات
                                            </li> -->
                                        </ul>
                                        <div class="property-price clearfix">
                                            <div class="read-more">
                                                <router-link
                                                    :to="
                                                        '/property/' +
                                                        property.id
                                                    "
                                                    class="theme-btn"
                                                    >التفاصيل</router-link
                                                >
                                            </div>
                                            <div class="price">
                                                {{ property.price_1 }} ريال
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Sidebar Side-->
                <div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside class="sidebar default-sidebar">
                        <!--search box-->
                        <div class="sidebar-widget sort-by">
                            <select
                                v-model="searchParams.order"
                                @change="reorder"
                                class="form-control"
                            >
                                <option value="0">ترتيب</option>
                                <option value="price-asc">
                                    سعر من الاقل للأعلى
                                </option>
                                <option value="price-desc">
                                    سعر من الاعلى للأقل
                                </option>
                                <option value="rate-asc">
                                    التقييم من الأقل للأعلى
                                </option>
                                <option value="rate-desc">
                                    التقييم من الأعلى للأقل
                                </option>
                            </select>
                        </div>

                        <!-- Categories -->
                        <div class="sidebar-widget search-properties">
                            <div class="sidebar-title">
                                <h3>بحث فى العقارات</h3>
                            </div>
                            <!-- Property Search Form -->
                            <div class="property-search-form style-three">
                                <form method="post">
                                    <div class="row no-gutters">
                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <input
                                                v-model="searchParams.q"
                                                type="text"
                                                placeholder="بحث باسم العقار او العنوان"
                                            />
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.category"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    أى قسم
                                                </option>
                                                <option
                                                    v-for="category in categories"
                                                    :value="category.id"
                                                    :key="category.id"
                                                >
                                                    {{ category.title }}
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.rooms"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الغرف
                                                </option>
                                                <option value="1">
                                                    غرفة واحدة
                                                </option>
                                                <option value="2">
                                                    غرفتين
                                                </option>
                                                <option value="3">3 غرف</option>
                                                <option value="4">4 غرف</option>
                                                <option value="5">5 غرف</option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.guests"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الضيوف
                                                </option>
                                                <option value="1">
                                                    شخص واحد
                                                </option>
                                                <option value="2">شخصين</option>
                                                <option value="3">
                                                    3 أشخاص
                                                </option>
                                                <option value="4">
                                                    4 أشخاص
                                                </option>
                                                <option value="5">
                                                    5 أشخاص
                                                </option>
                                                <option value="more">
                                                    أكثر
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.bathrooms"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    عدد الحمامات
                                                </option>
                                                <option value="1">
                                                    حمام واحد
                                                </option>
                                                <option value="2">
                                                    2 حمام
                                                </option>
                                                <option value="3">
                                                    3 حمامات
                                                </option>
                                                <option value="4">
                                                    4 حمامات
                                                </option>
                                                <option value="5">
                                                    5 حمامات
                                                </option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <select
                                                v-model="searchParams.rate"
                                                class="custom-select-box"
                                            >
                                                <option value="0">
                                                    اى تقييم
                                                </option>
                                                <option value="1">
                                                    1 أو أعلى
                                                </option>
                                                <option value="2">
                                                    2 أو أعلى
                                                </option>
                                                <option value="3">
                                                    3 أو أعلى
                                                </option>
                                                <option value="4">
                                                    4 أو أعلى
                                                </option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group">
                                            <div
                                                class="
                                                    range-slider-one
                                                    clearfix
                                                "
                                            >
                                                <label
                                                    >بحث بالسعر من - إلى</label
                                                >
                                                <div
                                                    class="price-range-slider"
                                                ></div>
                                                <div class="input">
                                                    <input
                                                        type="text"
                                                        class="price-amount"
                                                        name="field-name"
                                                        readonly
                                                        :value="
                                                            searchParams.price_from +
                                                            ' - ' +
                                                            searchParams.price_to
                                                        "
                                                    />
                                                </div>
                                                <div class="title">
                                                    ريال عمانى
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Form Group -->
                                        <div class="form-group row">
                                            <button
                                                type="button"
                                                @click.prevent="search()"
                                                class="
                                                    theme-btn
                                                    btn-style-one
                                                    col-6
                                                    d-inline-block
                                                "
                                            >
                                                بحث
                                            </button>
                                            <button
                                                class="
                                                    theme-btn
                                                    btn-secondary
                                                    col-6
                                                    d-inline-block
                                                "
                                                @click.prevent="reset()"
                                            >
                                                إعادة
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- End Form -->
                        </div>

                        <!-- Categories -->
                        <div class="sidebar-widget categories">
                            <div class="sidebar-title">
                                <h3>أقسام العقارات</h3>
                            </div>
                            <ul class="cat-list">
                                <li
                                    v-for="category in categories"
                                    :key="category.id"
                                >
                                    <a
                                        @click.prevent="
                                            $router.push({
                                                name: 'website.properties',
                                                query: {
                                                    category: category.id,
                                                },
                                            })
                                        "
                                        :href="
                                            '/properties?category=' +
                                            category.id
                                        "
                                        >{{ category.title }}
                                        <span>{{
                                            category.properties_count
                                        }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "properties",
    data() {
        return {
            currentPage: 1,
            more: false,
            searchParams: {
                price_from: 0,
                price_to: 0,
                bathrooms: 0,
                category: 0,
                rooms: 0,
                guests: 0,
                order: "price-asc",
                q: "",
                rate: 0,
            },
            properties: [],
            otherProperties: [],
            categories: [],
            favourites: [],
        };
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            Object.assign(this.searchParams, this.$route.query);
            this.getProperties();

            $(".price-range-slider").slider({
                range: true,
                min: 0,
                max: 10000,
                values: [
                    this.searchParams.price_from,
                    this.searchParams.price_to,
                ],
                slide: (event, ui) => {
                    this.searchParams.price_from = ui.values[0];
                    this.searchParams.price_to = ui.values[1];
                },
            });
        },
        search() {
            this.$router.push({
                name: "website.properties",
                query: this.searchParams,
            });
        },
        reset() {
            this.$router.push({
                name: "website.properties",
                query: {
                    price_from: 0,
                    price_to: 0,
                    bathrooms: 0,
                    category: 0,
                    rooms: 0,
                    guests: 0,
                    order: "price-asc",
                    q: "",
                    rate: 0,
                },
            });
        },
        reorder(e) {
            this.searchParams.order = e.target.value;
            this.$router.push({
                name: "website.properties",
                query: this.searchParams,
            });
        },
        getProperties(page = 1) {
            this.$axios
                .get("properties?page=" + page + "&" + this.urlQuery())
                .then((response) => {
                    this.currentPage = response.data.properties.current_page;
                    this.more = !!response.data.properties.next_page_url;

                    this.properties.push(...response.data.properties.data);
                    this.otherProperties = response.data.otherProperties;
                    this.categories = response.data.categories;
                    this.favourites = response.data.favourites ?? [];
                });
        },
        urlQuery(merge = {}) {
            Object.assign(this.searchParams, merge);

            return Object.keys(this.searchParams)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        "=" +
                        encodeURIComponent(this.searchParams[k])
                )
                .join("&");
        },
        toggleFavourite(property_id) {
            this.$axios
                .patch("/my/toggle-property-favourite/" + property_id)
                .then((res) => {
                    this.favourites = res.data.favourites;
                });
        },
        changeLocation(to) {
            this.$router.push(to);
        },
    },
    created() {
        this.$watch(
            () => this.$route.query,
            (toParams, previousParams) => {
                if (this.$route.name == "website.properties") {
                    this.properties = [];

                    this.searchParams = {
                        price_from: 0,
                        price_to: 0,
                        bathrooms: 0,
                        category: 0,
                        rooms: 0,
                        guests: 0,
                        order: "price-asc",
                        q: "",
                        rate: 0,
                    };

                    Object.assign(this.searchParams, this.$route.query);
                    this.getProperties();
                }
            }
        );
    },
};
</script>

<style scoped>
input.price-amount {
    direction: ltr;
}
.property-block .image-box .info {
    bottom: 0;
    direction: ltr;
}
.property-block .image-box .image img {
    aspect-ratio: 3/2.5 !important;
}

.pointer {
    cursor: pointer;
}
</style>